@if(isAuthenticated$ | async ; as isAuthenticated) {
<ng-container>
  <app-side-nav-outer-toolbar
    title="{{ appInfo.title }} | {{ appInfo.Mandant$ | async }}">
    <router-outlet></router-outlet>
    <app-footer>
      Copyright © {{ appInfo.currentYear }} crossVault GmbH (Version Anwendung
      {{ appInfo.AppVersion }} / Server {{ appInfo.BackendVersion }} [{{
        appInfo.BackendUptime
      }}])
    </app-footer>
  </app-side-nav-outer-toolbar>
</ng-container>
} @else { Bitte warten - Benutzer wird angemeldet }

<ng-template #unauthenticated>
  <div class="moodImage">
    <app-unauthenticated-content></app-unauthenticated-content>
  </div>
</ng-template>
