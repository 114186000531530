import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  NgModule,
  ModuleWithProviders,
  APP_INITIALIZER,
  Optional,
  SkipSelf,
} from '@angular/core';
import {
  AuthConfig,
  OAuthModule,
  OAuthModuleConfig,
  OAuthStorage,
} from 'angular-oauth2-oidc';
import { AuthGuard, AuthService } from './auth/auth.service';
import { authCodeFlowConfig } from './../../../config/authConfig';
import { authAppInitializerFactory } from './auth/auth-app-initializer.factory';
import { authModuleConfig } from './auth/auth-module-config';

import {
  MandantIdInterceptor,
  ErrorHandleInterceptor,
} from './interceptors/http';

// We need a factory since localStorage is not available at AOT build time
export function storageFactory(): OAuthStorage {
  return localStorage;
}

@NgModule({
  imports: [OAuthModule.forRoot()],
  providers: [
    AuthService,
    AuthGuard,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only'
      );
    }
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: MandantIdInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ErrorHandleInterceptor,
          multi: true,
        },
        {
          provide: APP_INITIALIZER,
          useFactory: authAppInitializerFactory,
          deps: [AuthService],
          multi: true,
        },
        { provide: OAuthStorage, useFactory: storageFactory },
        { provide: AuthConfig, useValue: authCodeFlowConfig },
        { provide: OAuthModuleConfig, useValue: authModuleConfig },
      ],
    };
  }
}
