<div class="viewerMain">
  <div class="photoViewerHeader">
    <div class="photoListHead"><div class="caption">Vor Lieferung</div></div>
    <div class="photoSelectedHead">
      <div class="caption">
        @if (history$ | async; as history) {
          @let selectedId = selectedGroupId$ | async;
          @let actionType = actionType$ | async;
          @let selectedPhoto = selectedPhoto$ | async;

          @if (actionType !== 'dispotour') {
            <dx-select-box
              [width]="'100%'"
              [dataSource]="history"
              valueExpr="rechnung_id"
              [displayExpr]="getGroupNameDetails"
              (onValueChanged)="onSelectGroup($event)"
              [value]="selectedId"></dx-select-box>
          } @else {
            @if (selectedPhoto) {
              <div class="font-bold text-lg">
                {{
                  selectedPhoto.photoType === 1
                    ? 'Vor Lieferung'
                    : 'Nach Lieferung'
                }}
              </div>
            }
          }
        }
      </div>
    </div>
    <div class="photoListHead"><div class="caption">Nach Lieferung</div></div>
  </div>

  <div class="photoViewer">
    <div class="photoList">
      @if (photosBefore$ | async; as photosBefore) {
        @for (photo of photosBefore; track photo.photo_id) {
          <div class="photo-item">
            <img
              [src]="
                '/data/photos/thumb/' + photo.azure_blob_id + '/120'
                  | useHttpImgSrc
              "
              role="button"
              (click)="selectedPhoto(photo.photo_id)"
              (keypress)="selectedPhoto(photo.photo_id)"
              aria-disabled="true"
              alt="Vorschau Foto" />
          </div>
        }
      }
    </div>

    <div class="photoSelected">
      @if (selectedPhoto$ | async; as selectedPhoto) {
        <div class="photo-full">
          <img
            [src]="
              '/data/photos/full/' + selectedPhoto.azure_blob_id + ''
                | useHttpImgSrc
            "
            aria-disabled="true"
            alt=" Foto" />
        </div>
      }
    </div>
    <div class="photoList">
      @if (photosAfter$ | async; as photosAfter) {
        @for (photo of photosAfter; track photo.photo_id) {
          <div class="photo-item">
            <img
              [src]="
                '/data/photos/thumb/' + photo.azure_blob_id + '/120'
                  | useHttpImgSrc
              "
              role="button"
              (click)="selectedPhoto(photo.photo_id)"
              (keypress)="selectedPhoto(photo.photo_id)"
              aria-disabled="true"
              alt="Vorschau Foto" />
          </div>
        }
      }
    </div>
  </div>
</div>
