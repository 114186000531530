<div
  class="welcomeFrame"
  [ngStyle]="{
    'background-image': 'url(' + backgroundImageUrl + ')',
  }">
  <div class="welcomeContent">
    <div class="flex flex-col items-center p-4">
      <img src="assets/moodimages/logo.png" alt="HBW Logo" />
    </div>
    <div class="greetingBox">
      {{ getGreeting() }}
      @if (userInfo$ | async; as user) {
        {{ user.vorname }}
      }
      !
    </div>
    @if (isProduction === false) {
      <div class="alert">
        <div>
          <p>
            Sie befinden sich in der TESTUMGEBUNG! Daten werden nicht Produktiv
            gespeichert! Produktionsumgebung ist
            <a href="https://central.hbw-flower.app/" target="_blank">
              https://central.hbw-flower.app/
            </a>
          </p>
        </div>
      </div>
    }

    <div class="versionBox">Version: {{ appVersion }}</div>
  </div>
</div>
