import { Component, OnInit, NgModule, Input, ViewChild } from '@angular/core';
import {
  SideNavigationMenuModule,
  HeaderModule,
} from '../../shared/components';
import { ScreenService } from '../../shared/services';
import { DxTreeViewTypes } from 'devextreme-angular/ui/tree-view';
import { DxDrawerModule } from 'devextreme-angular/ui/drawer';
import {
  DxScrollViewModule,
  DxScrollViewComponent,
} from 'devextreme-angular/ui/scroll-view';
import { CommonModule } from '@angular/common';

import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { OpenedStateMode, RevealMode } from 'devextreme/ui/drawer';
import { filter, map, startWith, tap } from 'rxjs';

@Component({
    selector: 'app-side-nav-outer-toolbar',
    templateUrl: './side-nav-outer-toolbar.component.html',
    styleUrls: ['./side-nav-outer-toolbar.component.scss'],
    standalone: false
})
export class SideNavOuterToolbarComponent implements OnInit {
  @Input()
  title!: string;
  @ViewChild(DxScrollViewComponent, { static: true })
  scrollView?: DxScrollViewComponent;
  selectedRoute = '';

  menuOpened!: boolean;
  temporaryMenuOpened = false;

  menuMode: OpenedStateMode = 'shrink';
  menuRevealMode: RevealMode = 'expand';
  minMenuSize = 0;
  shaderEnabled = false;
  isOnPhotoViewer = false;

  constructor(
    private screen: ScreenService,
    private router: Router
  ) {
    const initialNavigationEnd = new NavigationEnd(
      0,
      this.router.url,
      this.router.url
    );

    this.router.events
      .pipe(
        // Start with the initial navigation state
        startWith(initialNavigationEnd),
        // Filter for NavigationEnd events
        filter((event) => event && event instanceof NavigationEnd),
        // Map the event to a boolean indicating if the URL is '/photoviewer'
        map((event: NavigationEnd) =>
          this.checkIfOnPhotoViewer(event.urlAfterRedirects || event.url)
        ),
        tap((isOnPhotoViewer) => (this.isOnPhotoViewer = isOnPhotoViewer))
      )
      .subscribe();
  }

  private checkIfOnPhotoViewer(url: string): boolean {
    const isOnViewer = url.startsWith('/photoviewer');
    if (isOnViewer) {
      this.menuOpened = false;
      this.minMenuSize = 1;
    }
    return isOnViewer;
  }

  get hideMenuAfterNavigation() {
    return this.menuMode === 'overlap' || this.temporaryMenuOpened;
  }

  get showMenuAfterClick() {
    return !this.menuOpened;
  }

  ngOnInit() {
    this.menuOpened = this.screen.sizes['screen-large'];

    this.router.events.subscribe((val) => {
      if (val && val instanceof NavigationEnd) {
        this.selectedRoute = val.urlAfterRedirects.split('?')[0];

        console.log('selectedRoute', this.selectedRoute);
      }
    });

    this.screen.changed.subscribe(() => this.updateDrawer());

    this.updateDrawer();
  }

  updateDrawer() {
    const isXSmall = this.screen.sizes['screen-x-small'];
    const isLarge = this.screen.sizes['screen-large'];

    this.menuMode = isLarge ? 'shrink' : 'overlap';
    this.menuRevealMode = isXSmall ? 'slide' : 'expand';
    this.minMenuSize = isXSmall ? 0 : 60;
    this.shaderEnabled = !isLarge;
  }

  navigationChanged(event: DxTreeViewTypes.ItemClickEvent) {
    const path = event.itemData?.['path'];
    const pointerEvent = event.event;

    if (path && this.menuOpened) {
      if (event.node?.selected) {
        pointerEvent?.preventDefault();
      } else {
        this.router.navigate([path]);
        // eslint-disable-next-line @rx-angular/prefer-no-layout-sensitive-apis
        this.scrollView?.instance.scrollTo(0);
      }

      if (this.hideMenuAfterNavigation) {
        this.temporaryMenuOpened = false;
        this.menuOpened = false;
        pointerEvent?.stopPropagation();
      }
    } else {
      pointerEvent?.preventDefault();
    }
  }

  navigationClick() {
    if (this.showMenuAfterClick) {
      this.temporaryMenuOpened = true;
      this.menuOpened = true;
    }
  }
}

@NgModule({
  imports: [
    SideNavigationMenuModule,
    DxDrawerModule,
    HeaderModule,
    DxScrollViewModule,
    CommonModule,
  ],
  exports: [SideNavOuterToolbarComponent],
  declarations: [SideNavOuterToolbarComponent],
})
export class SideNavOuterToolbarModule {}
