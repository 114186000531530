import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  map,
  of,
  ReplaySubject,
  scan,
  shareReplay,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { DateUtils } from '../../utils/DateUtils';
const API_ENDPOINT = `${environment.BACKEND_URL}/data`;
@Injectable()
export class PhotoViewerPictureService {
  private _message$ = new BehaviorSubject<PhotoViewerMessage | null>(null);
  public actionType$: ReplaySubject<
    'dispocustomer' | 'dispocompare' | 'dispotour' | null
  > = new ReplaySubject(1);

  public showAfterPhoto$ = new BehaviorSubject<boolean>(true);
  public _selectedGroupId$ = new BehaviorSubject<number | null>(null);
  public _selectedPhotoId$ = new BehaviorSubject<number | null>(null);

  private _photos$ = this._message$.pipe(
    distinctUntilChanged((prev, curr) => {
      if (prev === null || curr === null) {
        // Emit if it changes from null to object or vice versa
        return prev === curr;
      }

      // Emit if 'action' changes
      if (prev.action !== curr.action) {
        return false;
      }

      // Emit if action is 'dispocustomer' and 'customerId' changes
      if (
        curr.action === 'dispocustomer' &&
        prev.customerId !== curr.customerId
      ) {
        return false;
      }

      // Emit if action is 'dispocompare' and 'dispoDetailId' changes
      if (
        curr.action === 'dispocompare' &&
        prev.dispoDetailId !== curr.dispoDetailId
      ) {
        return false;
      }

      if (
        curr.action === 'dispotour' &&
        prev.dispoStationId !== curr.dispoStationId
      ) {
        return false;
      }

      if (curr.action === 'dispotour' && prev.afterPhoto !== curr.afterPhoto) {
        return false;
      }

      // If none of the above conditions are met, do not emit
      return true;
    }),

    switchMap((message) => {
      this.actionType$.next(message?.action || null);

      if (message === null) {
        return of(null);
      }

      if (message.action === 'dispocustomer') {
        return this.httpClient
          .get<
            PhotoDispoListItem[]
          >(`${API_ENDPOINT}/photos/dispo/customer/${message.customerId}`)
          .pipe(map((photos) => this.recreateDates(photos)));
      }

      if (message.action === 'dispocompare') {
        return this.httpClient
          .get<
            PhotoDispoListItem[]
          >(`${API_ENDPOINT}/photos/dispo/compare/${message.dispoDetailId}`)
          .pipe(map((photos) => this.recreateDates(photos)));
      }

      if (message.action === 'dispotour') {
        this.showAfterPhoto$.next(message.afterPhoto || false);

        return this.httpClient
          .get<
            PhotoReturnTypeDispoTour[]
          >(`${API_ENDPOINT}/driversoffice/tour/photos/${message.dispoStationId}`)
          .pipe(map((photos) => this.mapDispoTourData(photos)));
      }

      return of(null);
    }),

    shareReplay({ bufferSize: 1, refCount: true })
  );

  public history$ = this._photos$.pipe(
    map((photos) => {
      if (photos === null) {
        return null;
      }

      // Use a Map to keep the latest entry of each rechnung_id
      const photosMap = new Map<number, PhotoDispoListItem>();

      photos.forEach((rechnung) => {
        const existing = photosMap.get(rechnung.rechnung_id);
        // Update the map if there's no existing entry or if the current rn_datum is later
        if (!existing || rechnung.rn_datum! > existing.rn_datum!) {
          photosMap.set(rechnung.rechnung_id, rechnung);
        }
      });

      // Convert the Map values to an array and sort by rn_datum in descending order
      return Array.from(photosMap.values()).sort(
        (a, b) => b.rn_datum!.getTime() - a.rn_datum!.getTime()
      );
    }),
    tap((photos) => {
      this._selectedGroupId$.next(
        photos?.length ? photos[0].rechnung_id : null
      );
      this._selectedPhotoId$.next(null);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private switchLoadedPhoto(afterPhoto: boolean, photos: PhotoDispoListItem[]) {
    if (!photos || Array.isArray(photos) === false) {
      return null;
    }

    const photoType = afterPhoto ? 2 : 1;
    return photos.find((photo) => photo.photoType === photoType);
  }

  private mapDispoTourData(
    photos: PhotoReturnTypeDispoTour[]
  ): PhotoDispoListItem[] {
    if (!photos || Array.isArray(photos) === false) {
      return [];
    }

    const today = new Date();
    return photos
      .filter((photo) => photo.photoType < 3)
      .map((photo, idx) => {
        const item: PhotoDispoListItem = {
          kunden_id: 0,
          azure_blob_id: photo.azure_blob_id,
          photo_id: idx,
          photoType: photo.photoType,
          liefer_datum: today,
          rn_datum: today,
          photo_ts: DateUtils.toJSDate(photo.ts),
          rechnung_id: 0,
          liefer_id: 0,
        };
        return item;
      });
  }
  private recreateDates(photos: PhotoDispoListItem[]) {
    if (!photos || Array.isArray(photos) === false) {
      return [];
    }

    return photos.map((photo) => {
      return {
        ...photo,
        rn_datum: DateUtils.toJSDate(photo.rn_datum),
        liefer_datum: DateUtils.toJSDate(photo.liefer_datum),
        photo_ts: DateUtils.toJSDate(photo.photo_ts),
      };
    });
  }

  private _photosInGroup$ = combineLatest({
    photos: this._photos$,
    selectedGroupId: this._selectedGroupId$.pipe(distinctUntilChanged()),
  }).pipe(
    map(({ photos, selectedGroupId }) => {
      if (photos === null || selectedGroupId === null) {
        return null;
      }

      return photos.filter((photo) => photo.rechnung_id === selectedGroupId);
    })
  );

  public set message(message: PhotoViewerMessage | null) {
    this._message$.next(message);
  }

  public photosBefore$ = this._photosInGroup$.pipe(
    map((photos) => {
      if (photos === null) {
        return null;
      }

      return photos.filter((photo) => photo.photoType === 1);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public photosAfter$ = this._photosInGroup$.pipe(
    map((photos) => {
      if (photos === null) {
        return null;
      }

      return photos.filter((photo) => photo.photoType === 2);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public selectedPhoto$ = combineLatest({
    photos: this._photosInGroup$,
    actionType: this.actionType$.pipe(distinctUntilChanged()),
    showAfterPhotos: this.showAfterPhoto$.pipe(distinctUntilChanged()),
    selectedPhotoId: this._selectedPhotoId$.pipe(distinctUntilChanged()),
  }).pipe(
    map(({ photos, selectedPhotoId, actionType, showAfterPhotos }) => {
      if (photos === null || photos.length === 0) {
        return null;
      }

      if (!selectedPhotoId) {
        if (actionType === 'dispotour') {
          return this.switchLoadedPhoto(showAfterPhotos, photos);
        }

        return photos[0];
      }

      return photos.find((photo) => photo.photo_id === selectedPhotoId);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  constructor(private httpClient: HttpClient) {}
}

export type PhotoViewerMessage = {
  action: 'dispocustomer' | 'dispocompare' | 'dispotour';
  customerId?: number;
  dispoDetailId?: number;
  dispoStationId?: number;
  afterPhoto?: boolean;
};

export type PhotoDispoListItem = {
  kunden_id: number;
  azure_blob_id: string;
  photo_id: number;
  photoType: number;
  liefer_datum?: Date;
  rn_datum?: Date;
  photo_ts?: Date;
  rechnung_id: number;
  liefer_id: number;
};

type PhotoReturnTypeDispoTour = {
  photoType: number;
  ts: Date;
  azure_blob_id: string;
};
