<div *ngIf="mandant$ | async as mandanten">
  <dx-validation-group>
    <div class="flex flex-col p-4">
      <div class="flex mb-2 items-baseline space-x-2">
        <div class="flex-0 min-w-[125px]">Mandant</div>
        <div class="flex-1">
          <dx-lookup
            displayExpr="beschreibung"
            valueExpr="_id"
            [dataSource]="mandanten"
            [(value)]="selectedMandantId"
            [inputAttr]="{ 'aria-label': 'Simple lookup' }"
            validationMessageMode="always"
          >
            <dxo-drop-down-options [showTitle]="false"></dxo-drop-down-options>

            <dx-validator>
              <dxi-validation-rule
                type="required"
                message="Bitte Mandant selektieren"
              ></dxi-validation-rule>
            </dx-validator>
          </dx-lookup>
        </div>
      </div>
      <div class="flex items-baseline space-x-2 mt-5">
        <div class="flex-1 flex justify-end space-x-2">
          <dx-button
            icon="check"
            type="success"
            text="Speichern"
            (onClick)="saveData($event)"
            [useSubmitBehavior]="true"
          >
          </dx-button>
        </div>
      </div>
    </div>
  </dx-validation-group>
</div>
