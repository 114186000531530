import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import config from 'devextreme/core/config';
import { DEVEXTREME_LICENSE_KEY } from './app/config/constants';
import { AppModule } from './app/app.module';
import * as Sentry from '@sentry/angular';
import { defineCustomElements as ioniconsLoader } from 'ionicons/dist/loader';

config({ licenseKey: DEVEXTREME_LICENSE_KEY });
ioniconsLoader(window);

Sentry.init({
  dsn: 'https://a9455d816e1fa59ddc307ed6e1c98ff2@o4507247992897536.ingest.de.sentry.io/4507248096575568',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/hbw-flower\.app\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
