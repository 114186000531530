import { b as bootstrapLazy } from './index-b72adede.js';
export { s as setNonce } from './index-b72adede.js';
const defineCustomElements = (win, options) => {
  if (typeof window === 'undefined') return undefined;
  return bootstrapLazy([["ion-icon", [[1, "ion-icon", {
    "mode": [1025],
    "color": [1],
    "ios": [1],
    "md": [1],
    "flipRtl": [4, "flip-rtl"],
    "name": [513],
    "src": [1],
    "icon": [8],
    "size": [1],
    "lazy": [4],
    "sanitize": [4],
    "svgContent": [32],
    "isVisible": [32]
  }]]]], options);
};
export { defineCustomElements };