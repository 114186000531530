<div class="user-panel">
  <div class="user-info" *ngIf="user$ | async as user">
    <div class="user-name">
      <i class="dx-icon-user"></i> {{ user?.vorname }} {{ user?.nachname }}
    </div>
  </div>
  <dx-context-menu
    *ngIf="menuMode === 'context'"
    [items]="menuItems"
    target=".user-button"
    showEvent="dxclick"
    width="210px"
    [position]="{
      my: 'top',
      at: 'bottom'
    }"
    cssClass="user-menu"
  >
  </dx-context-menu>
  <dx-list
    *ngIf="menuMode === 'list'"
    class="dx-toolbar-menu-action"
    [items]="menuItems"
  >
  </dx-list>
</div>
