<h2 class="content-block">Willkommen im HBW Kunden Portal</h2>

<div class="flex-row">
  <div class="flex-col card">
    <div class="header">Kundendaten</div>
    <div class="content">
      <div class="dx-fieldset">
        <div class="dx-field">
          <div class="dx-field-label">Kundennummer</div>
          <div class="dx-field-value-static">J6173</div>
        </div>

        <div class="dx-field">
          <div class="dx-field-label">Firmenname</div>
          <div class="dx-field-value-static">JET-Station</div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Name</div>
          <div class="dx-field-value-static">Fontaine Lydia</div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Adresse</div>
          <div class="dx-field-value-static">
            Vorstadter Str. 133<br />66793 Saarwellingen
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex-col card">
    <div class="header">Stationen</div>
  </div>
</div>

<div class="flex-row">
  <div class="flex-col"></div>
</div>

<div class="flex-row">
  <div class="flex-col card">
    <div class="header">Ihre letzten Rechnungen</div>
    <div class="content">
      <dx-list height="100%" [dataSource]="dataInvoice">
        <div *dxTemplate="let item of 'item'">
          <div class="invoiceLine">
            <div class="date">
              {{ item.datum }}
            </div>
            <div class="invoice">
              {{ item.rnr }}
            </div>
            <div class="amount">
              {{ item.rtotal }}
            </div>
            <div class="action">
              <dx-button icon="download" type="default"> </dx-button>
            </div>
          </div>
        </div>
      </dx-list>
    </div>
  </div>
</div>
