import { AppInfoService } from 'src/app/shared/services';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthStorage } from 'angular-oauth2-oidc';
import { Observable, catchError, finalize, tap, throwError } from 'rxjs';

export type HttpRequestState<T> = {
  isLoading: boolean;
  data?: T;
  error?: HttpErrorResponse | Error;
};

@Injectable()
export class MandantIdInterceptor implements HttpInterceptor {
  constructor(
    private authStorage: OAuthStorage,
    private appInfoService: AppInfoService
  ) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const mandantId = this.getInitialMandantId();

    this.appInfoService.startLoading();

    const modifiedReq = req.clone({
      headers: req.headers.set('X-Central-MandantId', mandantId.toString()),
    });

    return next.handle(modifiedReq).pipe(
      tap({
        next: (event) => {
          // **Handle the successful response here if needed**
          // console.log('HTTP response received', event);
        },
        error: (error) => {
          // **Handle the error response here**
          // console.error('HTTP request failed', error);
        },
      }),
      finalize(() => {
        // **Logic after the HTTP call completes (both success and error)**
        this.appInfoService.finishedLoading();
      })
    );
  }

  private getInitialMandantId(): number {
    let mandantId = parseInt(sessionStorage.getItem('mandantId') || '', 10);

    // Check if mandantId is a number (since getItem can return null if the key does not exist)
    if (!isNaN(mandantId)) {
      return mandantId;
    } else {
      return 0;
    }
  }
}

@Injectable()
export class ErrorHandleInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        console.log('Error on HTTP', error);
        return throwError(() => error);
      })
    );
  }
}
