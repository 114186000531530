import {
  PhotoDispoListItem,
  PhotoViewerPictureService,
} from './photoviewer.service';
import { CommonModule } from '@angular/common';
import {
  Component,
  DestroyRef,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Params } from '@angular/router';
import {
  combineLatest,
  distinct,
  distinctUntilChanged,
  filter,
  fromEvent,
  fromEventPattern,
  map,
  Subscription,
  tap,
} from 'rxjs';

import { DxSelectBoxModule } from 'devextreme-angular';
import { DateUtils } from '../../utils/DateUtils';
import { UseHttpImageSourcePipe } from '../../pipes/imageSourcePipe';

@Component({
  selector: 'app-photoviewerwindow',
  imports: [CommonModule, DxSelectBoxModule, UseHttpImageSourcePipe],
  providers: [PhotoViewerPictureService],
  templateUrl: './photoviewerwindow.component.html',
  styleUrl: './photoviewerwindow.component.scss',
})
export class PhotoviewerwindowComponent implements OnInit, OnDestroy {
  public readonly selectedGroupId$ =
    this.photoViewerPictureService._selectedGroupId$.asObservable();
  public readonly selectedPhotoId$ =
    this.photoViewerPictureService._selectedPhotoId$.asObservable();
  public readonly photosBefore$ = this.photoViewerPictureService.photosBefore$;
  public readonly photosAfter$ = this.photoViewerPictureService.photosAfter$;
  public readonly selectedPhoto$ =
    this.photoViewerPictureService.selectedPhoto$;
  public readonly history$ = this.photoViewerPictureService.history$;
  public readonly actionType$ = this.photoViewerPictureService.actionType$;

  private id: string | null = null;
  private broadcastChannel: BroadcastChannel = new BroadcastChannel(
    'photo-viewer'
  );

  private destroyRef = inject(DestroyRef);
  private id$;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly photoViewerPictureService: PhotoViewerPictureService
  ) {
    const messages$ = fromEventPattern<MessageEvent>(
      (handler) => this.broadcastChannel.addEventListener('message', handler),
      (handler) => this.broadcastChannel.removeEventListener('message', handler)
    ).pipe(
      takeUntilDestroyed(this.destroyRef),
      filter((event) => event.data.type === 'message'),
      map((event) => event.data)
    );

    this.id$ = this.activatedRoute.params.pipe(
      map((routeParams: Params) => routeParams?.['id']),
      distinctUntilChanged(),

      tap((paramId) => {
        this.id = paramId;
        this.broadcastChannel.postMessage({ id: this.id, type: 'ready' });
      }),
      takeUntilDestroyed(this.destroyRef)
    );

    combineLatest({
      id: this.id$,
      messages: messages$,
    })
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(({ id, messages }) => messages.id === id),
        map(({ id, messages }) => messages.payload),
        tap((payload) => {
          this.photoViewerPictureService.message = payload;
        })
      )
      .subscribe();
  }

  ngOnInit() {
    const params = new URLSearchParams(window.location.search);
    this.id = params.get('id');

    // Listen for messages from the parent
    this.broadcastChannel.onmessage = (event) => {
      const { id, type, payload } = event.data;
      if (type === 'message') {
        // Handle the message
      }
    };
  }

  ngOnDestroy() {
    // Notify the parent window that this window is closing
    if (this.id) {
      this.broadcastChannel.postMessage({ id: this.id, type: 'closed' });
    }
    // Close the broadcast channel
    this.broadcastChannel.close();
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  }

  // Alternatively, listen to the 'beforeunload' event
  ngAfterViewInit() {
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  }

  public getGroupNameDetails = (data: PhotoDispoListItem) => {
    if (data) {
      return `Rechnung vom ${DateUtils.toDateFormattedString(data.rn_datum, 'dd.MM.yyyy')} / Lieferung vom ${DateUtils.toDateFormattedString(data.liefer_datum, 'dd.MM.yyyy')}`;
    }
    return '';
  };

  public selectedPhoto(photoId: number | null) {
    this.photoViewerPictureService._selectedPhotoId$.next(photoId);
  }

  public onSelectGroup(event: any) {
    const photoId = event.value || null;

    this.photoViewerPictureService._selectedGroupId$.next(photoId);
  }

  handleBeforeUnload = () => {
    if (this.id) {
      this.broadcastChannel.postMessage({ id: this.id, type: 'closed' });
    }
    // Close the broadcast channel
    this.broadcastChannel.close();
  };
}
