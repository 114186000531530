import { Component, inject, OnInit } from '@angular/core';
import { AppInfoService, AuthService } from 'src/app/shared/services';
import { environment } from 'src/environments/environment';

@Component({
    templateUrl: 'home.component.html',
    styleUrls: ['./home.component.scss'],
    standalone: false
})
export class HomeComponent implements OnInit {
  public readonly isProduction = environment.production;
  public readonly userInfo$ = this.authService.userInfo$;
  public readonly appVersion = this.appInfo.AppVersion;
  backgroundImageUrl: string = '';

  constructor(
    private authService: AuthService,
    private appInfo: AppInfoService
  ) {}

  ngOnInit(): void {
    const randomNumber = Math.floor(Math.random() * 4) + 1; // Generates a random number between 1 and 4
    this.backgroundImageUrl = `/assets/moodimages/mood${randomNumber}.jpg`;
  }

  getGreeting(): string {
    const now = new Date();
    const hour = now.getHours();

    if (hour >= 4 && hour < 10) {
      return 'Guten Morgen, '; // Good morning
    } else if (hour >= 10 && hour < 18) {
      return 'Guten Tag '; // Good day/afternoon
    } else if (hour >= 18 && hour < 22) {
      return 'Guten Abend'; // Good evening
    } else {
      return 'Zeit zum Schlafen'; // Good night
    }
  }
}
